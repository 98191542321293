$md-button__B0: "elaine-six";
$md-button__A0: themed('element-action--text-color') !default;
$md-button__E0: themed('element-action:hover--bg-color') !default;
$md-button__E1: themed('element-action:active-highlight--bg-color') !default;


.gearMenu{
  right: 0 !important;
  left: calc(100% - 180px) !important;
  width: 180px;
}
.tb {
  display: flex;
  .right-menu {
    &__config-container {
      &--text{
           padding-right: 15px;
         }
      &--icon {
        &-big {
          font-size: 20px;
          padding-right: 5px;
          margin-top: 3px;
        }
        font-family: $md-button__B0;
        font-size: 16px;
        padding-right: 5px;
      }
      display: flex;
      align-items: center;
      color: #c5c5c5;
      border-right: 1px solid #000000
    }
    margin-left: auto;
    display: flex;
  }

  .md-button {
    &[disabled] {
      opacity: .5;
    }

    &.tb__button {
      font-size: 12px;
      border: none;
      border-radius: 0;
      color: $md-button__A0;
      height: 30px;
      min-height: 30px;
      line-height: 30px;
      outline: none;
      vertical-align: top;
      text-align: center;
      display: inline-block;
      margin: 0;
      padding: 0 10px;
      text-transform: none;
      border-right: 1px solid #000000;

      &:focus {
        outline: none;
      }

      &:not( .tb__button--icon ) {
        min-width: 60px;
        width: auto;
      }

      &.tb__button--icon {
        min-width: 30px;
        height: 30px;
        width: 30px;
        padding: 0;
        font-family: $md-button__B0;
      }

      &.active {
        background: $md-button__E0;
      }

      &[disabled] {
        opacity: .4;
      }

      &:hover {
        &:not( [disabled] ) {
          background: $md-button__E1;
        }
      }
    }
  }

  .survey{
    img {
        width: 16px;
        margin: 7px;
    }
  }
}
