// ELAINE Theme definition

@import 'basics';
@import 'colors';

$theme-version: "dark";

$mappedPalette : map-get( $palettes, $theme-version);

$themes: (
  'dark': (

    // Toaster
    'success--color': map-get($mappedPalette, 'artegic-green'),
    'error--color': map-get($mappedPalette, 'artegic-red'),
    'warning--color': map-get($mappedPalette, 'artegic-orange'),
    'info--color': map-get($mappedPalette, 'artegic-blue'),

    // Text, labels, lists
    'base--bg-color' :map-get($mappedPalette, 'base-grey-08'),
    'base--text-color' :map-get($mappedPalette, 'base-white'),
    'base:odd--bg-color' :map-get($mappedPalette, 'base-grey-09'), // more contrast color

    'base:accent--text-color' :map-get($mappedPalette, 'artegic-corporate-green'),
    'base:accent--color' :map-get($mappedPalette, 'artegic-corporate-green'),
    'base:accent-dark--color' :map-get($mappedPalette, 'artegic-corporate-green'),

    // Buttons
    'element-action--bg-color' :map-get($mappedPalette, 'base-grey-04'),
    'element-action--text-color' :map-get($mappedPalette, 'base-white'),
    'element-action:active--bg-color' :map-get($mappedPalette, 'artegic-corporate-green'),
    'element-action:active-highlight--bg-color' :map-get($mappedPalette, 'artegic-corporate-green--dark'),
    'element-action:active-alternative--bg-color' : $elaine-blue-dark--M1,

    'element-action:hover--bg-color' :map-get($mappedPalette, 'artegic-corporate-green'),
    'element-action:hover-cancel--bg-color' :map-get($mappedPalette, 'artegic-red'),
    'element-action:constrast--bg-color' :map-get($mappedPalette, 'base-white'),
    //'element-action:disabled--text-color' :map-get($mappedPalette, 'base-grey-08'),

    // list elements
    'list--bg-color' :map-get($mappedPalette, 'base-grey-09'),
    'list:odd--bg-color' :map-get($mappedPalette, 'base-grey-09'),
    'list:even--bg-color' :map-get($mappedPalette, 'base-grey-08'),
    'list--text-color' :map-get($mappedPalette, 'base-white'),
    'list:selected--text-color' :map-get($mappedPalette, 'base-white'),
    'list:selected--bg-color' :map-get($mappedPalette, 'artegic-corporate-green'),

    // table like list elements
    'tablelist--bg-color' :map-get($mappedPalette, 'base-grey-05'), //even
    'tablelist:odd--bg-color' :map-get($mappedPalette, 'base-grey-07'),
    'tablelist--text-color' :map-get($mappedPalette, 'base-white'),

    // Inputs, selectes, textareas,...
    'form-element--text-color' :map-get($mappedPalette, 'base-white'),
    'form-element--bg-color' :map-get($mappedPalette, 'base-grey-07'),
    'form-element:focus--bg-color' :map-get($mappedPalette, 'base-grey-01'),
    'form-element:error--color' :map-get($mappedPalette, 'artegic-red'),

    // Select options
    'form-list--text-color' :map-get($mappedPalette, 'base-grey-08'),
    'form-list--bg-color' :map-get($mappedPalette, 'base-grey-08'),
    'form-list:selected--text-color' :map-get($mappedPalette, 'base-grey-08'),
    'form-list:selected--bg-color' :map-get($mappedPalette, 'base-grey-08'),
    'form-list:disabled--text-color' :map-get($mappedPalette, 'base-grey-08'),
    'form-list:disabled--bg-color' :map-get($mappedPalette, 'base-grey-08'),
    'form-list:hover--text-color' :map-get($mappedPalette, 'base-grey-08'),
    'form-list:hover--bg-color' :map-get($mappedPalette, 'base-grey-08'),

    // Menu, multiselect options
    'list-reverse--text-color' :map-get($mappedPalette, 'base-white'),
    'list-reverse--bg-color' :map-get($mappedPalette, 'base-grey-07'),
    'list-reverse:selected--text-color' :map-get($mappedPalette, 'base-white'),
    'list-reverse:selected--bg-color' :map-get($mappedPalette, 'artegic-corporate-green'),
    //'list-reverse:disabled--text-color' :map-get($mappedPalette, 'base-grey-08'),
    //'list-reverse:disabled--bg-color' :map-get($mappedPalette, 'base-grey-08'),
    'list-reverse:hover--text-color' :map-get($mappedPalette, 'base-white'),
    'list-reverse:hover--bg-color' :map-get($mappedPalette, 'artegic-corporate-green'),

    // Grid layout, icon-menu backgrounds, separations
    'frame--bg-color' :map-get($mappedPalette, 'base-black'),
    'frame--text-color' :map-get($mappedPalette, 'base-white'),

     // Layer colors
     'layer-0--color' :map-get($mappedPalette, 'base-white'),
     'layer-1--color' : $elaine-blue-light--m1,
     'layer-2--color' : $elaine-purple-light--m1,
     'layer-3--color' : $elaine-red-light--m1,
     'layer-4--color' : $elaine-ocre-light--m1,
     'layer-5--color' : $elaine-green-light--m1,

     //Neutral color: arrows
     'neutral--color' : map-get($mappedPalette, 'base-grey-06'),
   ),

  'light': (

    // Toaster
          'success--color': map-get($mappedPalette, 'artegic-green'),
          'error--color': map-get($mappedPalette, 'artegic-red'),
          'warning--color': map-get($mappedPalette, 'artegic-orange'),
          'info--color': map-get($mappedPalette, 'artegic-blue'),

    // Text, labels, lists
          'base--bg-color' :map-get($mappedPalette, 'base-grey-00'),
          'base--text-color' :map-get($mappedPalette, 'base-black'),
          'base:odd--bg-color' :map-get($mappedPalette, 'base-grey-09'), // more contrast color

          'base:accent--text-color' :map-get($mappedPalette, 'artegic-corporate-green'),
          'base:accent--color' :map-get($mappedPalette, 'artegic-corporate-green'),
          'base:accent-dark--color' :map-get($mappedPalette, 'artegic-corporate-green'),

    // Buttons
          'element-action--bg-color' :map-get($mappedPalette, 'base-grey-02'),
          'element-action--text-color' :map-get($mappedPalette, 'base-black'),
          'element-action:active--bg-color' :map-get($mappedPalette, 'artegic-corporate-green'),
          'element-action:hover--bg-color' :map-get($mappedPalette, 'artegic-corporate-green'),
          'element-action:hover-cancel--bg-color' :map-get($mappedPalette, 'artegic-red'),
          'element-action:constrast--bg-color' :map-get($mappedPalette, 'base-white'),
    //'element-action:disabled--text-color' :map-get($mappedPalette, 'base-grey-08'),

    // list elements
          'list--bg-color' :map-get($mappedPalette, 'base-grey-02'),
          'list:odd--bg-color' :map-get($mappedPalette, 'base-grey-03'),
          'list--text-color' :map-get($mappedPalette, 'base-black'),
          'list:selected--text-color' :map-get($mappedPalette, 'base-black'),
          'list:selected--bg-color' :map-get($mappedPalette, 'artegic-corporate-green'),

    // table like list elements
      'tablelist--bg-color' :map-get($mappedPalette, 'base-grey-05'), //even
      'tablelist:odd--bg-color' :map-get($mappedPalette, 'base-grey-07'),
      'tablelist--text-color' :map-get($mappedPalette, 'base-white'),

    // Inputs, selectes, textareas,...
          'form-element--text-color' :map-get($mappedPalette, 'base-black'),
          'form-element--bg-color' :map-get($mappedPalette, 'base-grey-02'),
          'form-element:focus--bg-color' :map-get($mappedPalette, 'base-grey-01'),
          'form-element:error--color' :map-get($mappedPalette, 'artegic-red'),

    // Select options
          'form-list--text-color' :map-get($mappedPalette, 'base-grey-08'),
          'form-list--bg-color' :map-get($mappedPalette, 'base-grey-08'),
          'form-list:selected--text-color' :map-get($mappedPalette, 'base-grey-08'),
          'form-list:selected--bg-color' :map-get($mappedPalette, 'base-grey-08'),
          'form-list:disabled--text-color' :map-get($mappedPalette, 'base-grey-08'),
          'form-list:disabled--bg-color' :map-get($mappedPalette, 'base-grey-08'),
          'form-list:hover--text-color' :map-get($mappedPalette, 'base-grey-08'),
          'form-list:hover--bg-color' :map-get($mappedPalette, 'base-grey-08'),

    // Menu, multiselect options
          'list-reverse--text-color' :map-get($mappedPalette, 'base-white'),
          'list-reverse--bg-color' :map-get($mappedPalette, 'base-grey-07'),
          'list-reverse:selected--text-color' :map-get($mappedPalette, 'base-white'),
          'list-reverse:selected--bg-color' :map-get($mappedPalette, 'artegic-corporate-green'),
    //'list-reverse:disabled--text-color' :map-get($mappedPalette, 'base-grey-08'),
    //'list-reverse:disabled--bg-color' :map-get($mappedPalette, 'base-grey-08'),
          'list-reverse:hover--text-color' :map-get($mappedPalette, 'base-white'),
          'list-reverse:hover--bg-color' :map-get($mappedPalette, 'artegic-corporate-green'),

    // Grid layout, icon-menu backgrounds, separations
          'frame--bg-color' :map-get($mappedPalette, 'base-grey-10'),
          'frame--text-color' :map-get($mappedPalette, 'base-white'),

    // Layer colors
          'layer-0--color' :map-get($mappedPalette, 'base-white'),
          'layer-1--color' : $elaine-blue-basic,
          'layer-2--color' : $elaine-purple-basic,
          'layer-3--color' : $elaine-red-basic,
          'layer-4--color' : $elaine-ocre-basic,
          'layer-5--color' : $elaine-green-basic,

    //Neutral color: arrows
    'neutral--color' : map-get($mappedPalette, 'base-grey-07'),
  )
);

//$appFont: "Open-sans";

//-------------------------------

//themed('action-element--bg-color')

@function themed( $key ) {
  @return map-get( map-get($themes, $theme-version) , $key)
}



//$pxs: ();
//@each $key, $color in map-get($themes, $theme-version) {
//  //@debug $key;
//  $key: $color;
//}




