//TabBrowser
#TabBrowser_tabs{
  .tab-content{
    .frame_in_tab{
      .preview_wrapper{
        border-bottom: 2px solid #000;
      }
      body{
        overflow-x: auto;
      }
      .api-dashboard-frame {
        height: 100%;
        width: 100%;
        border: 0;
      }
    }
  }
  ul{
    &.TabBrowser_tablist{
      li{
        a{
          &:hover{
            background-color: transparent;
            color: #fefefe;
            border: none;
            border-bottom: 2px solid #6d891b;
          }
          padding: 5px 10px;
          color: #fefefe;
          font-weight: 100;
          outline: none;
          height: 100%;
        }
        &.active,&:hover{
          a{
            background-color: transparent;
            color: #fefefe;
            border: none;
            border-bottom: 2px solid #6d891b;
          }
        }
        height: 100%;
        margin-bottom: 0;
        border-right: 1px solid #000;
      }
      *{
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      margin: 0;
      padding: 0;
      height: 32px;
      border: 0;
      border-bottom: 2px solid #000;
    }
  }
  border:0;
  outline: none;
}

.elaine-tab-page{
  &.with-navigation{
    .elaine-tab-page-single{
      height: calc( 100% - 35px );
    }
  }
  .elaine-tab-page-noData {
    display: block;
    width: 100%;
    height: auto;
    padding: 10px;
    font-size: 12px;
    color: #fefefe;
  }
  .elaine-tab-page-navigation{
    .e_form{
      &.ng-enter {
        opacity: 0;
      }
      &.ng-enter-active {
        opacity: 1;
      }
      &.ng-leave {
        opacity: 1;
      }
      &.ng-leave-active {
        opacity: 0;
      }
      .styled-select{
        display: inline-block;
        width: 175px;
      }
      color: #fefefe;
      -moz-transition: .75s linear all;
      -webkit-transition: .75s linear all;
      transition: .75s linear all;
    }
    .em-editor-checkbox{
      width: 200px;
      display: inline-block;
      vertical-align: top;
      padding-left: 10px;
      padding-top: 5px;
    }
    .em-editor-checkbox-toggle{
      label{
        width: auto;
      }
      .switch-item{
        margin-top: 7px;
      }
      width: auto;
      display: inline-block;
      vertical-align: top;
      padding: 0 5px;
    }
    *{
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    height: auto;
    width: 100%;
    display: block;
    border-bottom: 1px solid #000;
  }
  .elaine-tab-page-single{
    .elaine-tab-page-single-inner{
      .elaine-form-preview-panel {
        .elaine-form-preview-panel-inner {
          display: block;
          width: 950px;
          margin: 0 auto;
          padding: 20px 0;
        }
        position: relative;
        height: 100%;
      }
      .em-variant{
        overflow: hidden;
      }
      .em-variant-inner-lines{
        display: none;
      }
      div[ui-layout-container="east"]{
        .em-stage-tabs{
          .e_form{
            .form-group-select{
              .styled-select{
                width: 100%;
              }
              width: 100%;
            }
            height: 100%
          }
          .nav{
            &.nav-tabs{
              >li{
                &.active{
                  >a{
                    background-color: transparent;
                    color: #fefefe;
                    border: none;
                    border-bottom: 2px solid #6d891b;
                  }
                }
                >a{
                  &:hover{
                    background-color: transparent;
                    color: #fefefe;
                    border: none;
                    border-bottom: 2px solid #6d891b;
                  }
                  height: 100%;
                  line-height: 30px;
                  padding: 0 10px;
                  background: transparent;
                  border: none;
                  color: #fefefe;
                  text-decoration: none;
                  cursor: pointer;
                }
                height: 100%;
                border-right: 1px solid #000;
              }
              border-bottom: 1px solid #000;
              height: 31px;
            }
          }
        }
      }
      display: block;
      width: 100%;
      height: 100%;
    }
    display: block;
    width: 100%;
    height: 100%;
  }
  .elaine-tab-page-with-sidebar{
    .elaine-tab-page-with-sidebar-view{
      .elaine-tab-page-with-sidebar-view-inner{
        .clicks-overview-table{
          .table-responsive{
            .tree-grid{
              .tree-grid-td-inner{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
                max-width: 500px;
              }
              .tree-label{
                cursor: default;
              }
              .level-2{
                .tree-grid-td-inner{
                  width: calc( 100% - 20px );
                }
              }
              .level-3{
                .tree-grid-td-inner{
                  width: calc( 100% - 40px );
                }
              }
              width: 100%;
            }
          }
          height: 100%;
          padding: 1px 0 0 !important;
        }
        .em-editor{
          .em-editor-views{
            .em-preview{
              .em-preview-left{
                .em-pane-inner{
                  .elaine-preview-editor-stage-inner{
                    .em-editor-stage-inner {
                      height: 100%;
                    }
                    height: 100%;
                  }
                  .em-variant{
                    overflow: hidden;
                  }
                  .em-variant-inner-lines{
                    display: none;
                  }
                  width: 100%;
                }
                .elaine-spinner{
                  top: 25%;
                }
                width: 100%;
              }
            }
            height: 100%;
          }
        }
        display: block;
        width: 100%;
        height: 100%;
      }
      display: block;
      height: 100%;
    }
    .elaine-tab-page-with-sidebar-sidebar{
      .elaine-tab-page-with-sidebar-sidebar-inner{
        .elaine-tab-page-sidebar{
          display: block;
          height: 100%;
          width: 100%;
        }
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
      }
      display: block;
      height: 100%;
    }
    width: 100%;
    height: calc( 100% - 35px );
    display: block;
  }
  .elaine-tab-page-loading{
    .elaine-spinner{
      top: 25%;
    }
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background: #282e2c;
  }
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
}

//new
.elaine-panels-tabs-container{
  .elaine-panels-tabs{
    .elaine-panels-tabs-item{
      .elaine-panels-tabs-item-link{
        &:hover,&.active{
          background-color: transparent;
          color: #fefefe;
          border: none;
          border-bottom: 2px solid #6d891b;
        }
        display: block;
        width:auto;
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        color: #fefefe;
        text-decoration: none;
        outline: none;
        cursor: pointer;
      }
      display: block;
      width:auto;
      height: 30px;
      line-height: 30px;
      float: left;
      margin-bottom: -1px;
      position: relative;
    }
    display: block;
    width: 100%;
    height: 30px;
    border-bottom: 1px solid #000;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .elaine-panels-inner-container{
    .elaine-panels-loading{
      &.ng-hide-add,&.ng-hide-remove{
        -webkit-transition: .35s ease-in-out all;
        -moz-transition: .35s ease-in-out all;
        -ms-transition: .35s ease-in-out all;
        -o-transition: .35s ease-in-out all;
        transition: .35s ease-in-out all;
      }
      &.ng-hide{
        opacity: 0;
      }
      .elaine-spinner{
        top: 33%;
      }
      opacity: 1;
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1001;
      top: 0;
      left: 0;

      bottom: 0;
      right: 0;
      background: #282e2c;
    }
    .elaine-panels-inner-container-view{
      &.ng-enter, &.ng-leave {
        opacity: 0;
        -moz-transition: .5s linear all;
        -webkit-transition: .5s linear all;
        transition: .5s linear all;
      }
      &.ng-enter-active,&.ng-leave-active {
        opacity: 0;
      }
      display: block;
      width: 100%;
      height: 100%;
      opacity: 1;
    }
    display: block;
    width: 100%;
    height: calc( 100% - 30px );
    position:relative;
  }
}
